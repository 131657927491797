/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ description, lang, meta, title }) => {
  const { site, ogp } = useStaticQuery(
    graphql`
      query {
        ogp: file(relativePath: { eq: "ogp.jpg" }) {
          publicURL
        }
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata.title;
  const image = `${site.siteMetadata?.siteUrl}${ogp.publicURL}`;

  return (
    <Helmet
      title={defaultTitle}
      defer={false}
      meta={[
        { name: "description", content: metaDescription },
        { property: "og:image", content: image },
        { property: `og:title`, content: defaultTitle },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { name: `twitter:card`, content: `summary_large_image` },
        { name: `twitter:title`, content: defaultTitle },
        { name: `twitter:description`, content: metaDescription },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
